// If we support SVG, all SVG-capable elements should be switched over.
var initSvg = function() {
  $('[data-svg-source]').each(function(){
    if ($(this).attr('data-svg-source').length) {
      var newImg = $(this).clone();
      newImg.attr('src', $(this).attr('data-svg-source'));
      $(this).replaceWith(newImg);
    }
  });
};

if ($('html').hasClass('svg')) {
  // Modernizr got in early.
  initSvg();
}
else if (!!window.Modernizr && !!window.Modernizr.svg) {
  // If we have Modernizr, use it.
  initSvg();
}
else {
  // No Modernizr? No problem.
  // https://css-tricks.com/test-support-svg-img/
  var img = new Image();
  img.onerror = function () {
    // Do nothing.
  };
  img.onload = function () {
    initSvg();
  };
  // 1px x 1px SVG; must be base64 or URI encoded for IE9... base64 is shorter
  img.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjwvc3ZnPg==';
}

// @file
// Stories landing page.

// Wrap trending and latest panels in a container
$(".latest-panel, .trending-panel").wrapAll('<div class="latest-trending-container"></div>');
$latest_title = $(".latest-panel .block-title");
$trending_title = $(".trending-panel .block-title");

// Hide Trending panel by default
$(".trending-panel .content").hide();
$trending_title.addClass('panel-inactive');

// Toggle visibility of latest/trending panels
$latest_title.click(function(){
  $(".latest-panel .content").show();
  $(".trending-panel .content").hide();
  $(this).removeClass('panel-inactive');
  $trending_title.addClass('panel-inactive');
});

$trending_title.click(function(){
  $(".latest-panel .content").hide();
  $(".trending-panel .content").show();
  $(this).removeClass('panel-inactive');
  $latest_title.addClass('panel-inactive'); 
});

// Assign class to the first paragraph if it is a curated panel
// Assign class to the first node within the first curated panel

$first_paragraph = $(".paragraphs-item-landing-page-tiles:first");

if ($first_paragraph.hasClass('curated-dark-panel')) {
  $first_paragraph.addClass('first-panel');
}

$first_story = $('.first-panel .view-content > div:first-of-type');
$first_story.addClass('first-story');

$("#views-exposed-form-stories-landing-block").parents(".entity-paragraphs-item").addClass("search-paragraph");

// Prevents user interaction with exposed filters while a view is doing an AJAX load.
if (
  !!Drupal &&
  !!Drupal.settings &&
  !!Drupal.settings.views &&
  !!Drupal.settings.views.ajaxViews
) {
  // Keeps track of the last focused element while our ajax loads
  var exposedViewPrevFocus = null,
  exposedViewCollapsers = {};

  // Spawns an element to block mouse interaction with the form
  var spawnAjaxBlocker = function(form){
    var ab = $('<span>', {
      'class': 'ajax-blocker'
    });
    ab.on('mousedown mouseup click', function(e){
      e.preventDefault();
      e.stopPropagation();
    });
    form.prepend(ab);
  };

  var ieCollapsible = function() {
    var isIE = $('html').attr('data-useragent-msie');
    if (!!isIE && parseInt(isIE, 10) < 11) {
      var sel = '.slnsw-view-filters fieldset > legend a';
      $(document).on('mouseover', sel, function(e) {
        if (SlnswTheme.resize.breakpoints.isNotMobile()) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          $(this).addClass('msie-block');
        }
      });
      $(document).on('mouseout', sel, function(e) {
        if (SlnswTheme.resize.breakpoints.isNotMobile()) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          $(this).removeClass('msie-block');
        }
      });
      $(document).on('mousedown mouseup click', sel, function(e) {
        if (SlnswTheme.resize.breakpoints.isNotMobile()) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        }
      });
    }
  };

  var initCollapsibles = function(){
    SlnswTheme.CollapserHelpers.unifyState(
      SlnswTheme.resize.breakpoints.isNotMobile()
    );
    ieCollapsible();
    exposedViewCollapsers = {};
  };

  var lockForm = function(form) {
    spawnAjaxBlocker(form);
    exposedViewCollapsers = SlnswTheme.CollapserHelpers.getState();
    form.attr({
      'data-slnswtheme-exposedform': 'locked',
      'aria-busy': 'true'
    }).blur();
  };

  // On AJAX send
  $(document).on('ajaxSend', function(e, xhr, settings){
    exposedViewPrevFocus = null;
    if (settings.url == Drupal.settings.views.ajax_path) {
      var view_id = null;
      // Identify the view DOM ID by hook or by crook
      if (!!settings.extraData && !!settings.extraData.view_dom_id) {
        view_id = settings.extraData.view_dom_id;
      }
      else if (!!settings.data) {
        var matches = settings.data.match('view_dom_id=([^&]+)');
        if (!!matches && !!matches.length) {
          view_id = matches[1];
        }
      }

      // We got a DOM ID, we can lock down the form associated with it
      if (!!view_id) {
        var instanceIndex = 'views_dom_id:' + view_id,
        instance = Drupal.views.instances[instanceIndex];
        if (!!instance) {
          exposedViewPrevFocus = $(':focus').get(0);
          lockForm(instance.$exposed_form);
        }
      }
    }
  });

  // On cessation of all AJAX activity
  $(document).on('ajaxStop', function(e, xhr, settings){
    $('form[data-slnswtheme-exposedform="locked"] > .ajax-blocker').remove();
    $('form[data-slnswtheme-exposedform="locked"]').removeAttr('data-slnswtheme-exposedform aria-busy');
    if (!!exposedViewPrevFocus) {
      $(exposedViewPrevFocus).focus();
      exposedViewPrevFocus = null;
    }
    SlnswTheme.CollapserHelpers.setState(exposedViewCollapsers);
    exposedViewCollapsers = {};
  });

  initCollapsibles();
}


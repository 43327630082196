// @file
// Trigger hover state on shared links.
// But only on non-touch devices.

// Since this is a legacy JS file,
// we're disabling a bunch of ESLint checks.
// @see CW-2256

/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable comma-dangle */

var hasTouch = (
  ('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0)
);

SlnswTheme.BlockPrimaryLink = {};

SlnswTheme.BlockPrimaryLink.findBlock = function (elem) {
  return $(elem).closest('.feature-block');
};

SlnswTheme.BlockPrimaryLink.findPrimary = function (elem) {
  return $(elem).closest('.block-contents').find('.block-primary-link');
};

SlnswTheme.BlockPrimaryLink.hoverOn = function (elem, proxied) {
  if (proxied) {
    $(elem).addClass('hover');
  }
  SlnswTheme.BlockPrimaryLink.findPrimary(elem).addClass('hover');
  $(document).trigger('BlockPrimaryLink.hover', {
    elem: SlnswTheme.BlockPrimaryLink.findBlock(elem).get(0),
    on: true
  });
};

SlnswTheme.BlockPrimaryLink.hoverOff = function (elem, proxied) {
  if (proxied) {
    $(elem).removeClass('hover');
  }
  SlnswTheme.BlockPrimaryLink.findPrimary(elem).removeClass('hover');
  $(document).trigger('BlockPrimaryLink.hover', {
    elem: SlnswTheme.BlockPrimaryLink.findBlock(elem).get(0),
    on: false
  });
};

if (!hasTouch) {
  $('body').addClass('slnswtheme-allow-hover');
  $(document).on('mouseenter focus', '.block .block-primary-link', function () {
    SlnswTheme.BlockPrimaryLink.hoverOn(this, false);
  });
  $(document).on('mouseleave blur', '.block .block-primary-link', function () {
    SlnswTheme.BlockPrimaryLink.hoverOff(this, false);
  });
}

// @file
// Initialise all theme JS.

/* eslint-disable no-undef */
/* eslint-disable comma-dangle */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable new-cap */
/* eslint-disable no-var */
/* eslint-disable prefer-template */
/* eslint-disable prefer-arrow-callback */

// Enable smooth anchor transitions.
$(document).anchor(
  {
    //disable the anchor icon on hover
    anchorIcon: false
  }
);

SlnswTheme.PrintWatch.init();

SlnswTheme.UserAgent.init();

SlnswTheme.resize.init($);

SlnswTheme.ZoomEvent.init($, {
  threshold: 0.05,
  round: 10
});

$('[data-slnswtheme-maxwidth], [data-slnswtheme-maxheight], [data-slnswtheme-matchheight]').matchSize();

SlnswTheme.pagePop.init($);

// Only doing the form disable for webforms to start with.
// @see WSB-3405
SlnswTheme.SubmitOnceSingleton = (
  new SlnswTheme.SubmitOnce($, '.webform-client-form')
);

// Initialise scroller.
SlnswTheme.ScrollerHelper.ScrollerRunnerSingleton = (
  new SlnswTheme.ScrollerHelper.ScrollerRunner($, [
    SlnswTheme.ScrollerHelper.HeaderScroll,
  ])
);
SlnswTheme.ScrollToAttrSingleton = (
  new SlnswTheme.ScrollToAttr($, SlnswTheme.ScrollerHelper.ScrollerRunnerSingleton)
);

// Initialise popovers.
Drupal.behaviors.SlnswThemePopover = {
  attach: function (context, settings) {
    SlnswTheme.Popover.init($, context);
  }
};
SlnswTheme.Popover.init($);

// Initialise collection item UI.
SlnswTheme.collectionItem.init($);

// Initialise masonry.
if (!SlnswTheme.PrintWatch.currentPrintState) {
  SlnswTheme.Masonry.init();
}
Drupal.behaviors.themeMasonryInit = {
  attach: function (context, settings) {
    if (SlnswTheme.PrintWatch.currentPrintState) {
      return;
    }
    SlnswTheme.Masonry.init(context);
  }
};

// Feature display block FX.
SlnswTheme.featureBlockDisplaySingleton = (
  new SlnswTheme.featureBlockDisplay($, SlnswTheme, {
    scope: '.main-container'
  })
);
Drupal.behaviors.themeFeatureBlockBehaviour = (
  SlnswTheme.featureBlockDisplaySingleton.getBehaviours()
);
SlnswTheme.featureBlockDisplaySingleton
  .setupSizing(
    SlnswTheme.debounceActions,
    SlnswTheme.PrintWatch.printActions,
    SlnswTheme.PrintWatch.notPrintActions
  );
SlnswTheme.featureBlockDisplaySingleton
  .setupEventUpdate('BlockPrimaryLink.hover', 250, 5);
SlnswTheme.featureBlockDisplaySingleton
  .setupFX();

// Collapser: Nav
SlnswTheme.collapsers.navBehaviour = new SlnswTheme.CollapserTool(jQuery, {
  selectors: {
    base: '[data-slnswtools-nav-behaviour]',
    sets: {
      mobile: '[data-slnswtools-nav-behaviour="collapse-mobile"]',
      always: '[data-slnswtools-nav-behaviour="collapse-always"]'
    },
  },
  actions: {
    initElem: function ($, elem, _setName, showNow) {
      if (elem.data('bs.collapse')) {
        // Gets around weird post-ajax behaviour
        if (showNow) {
          elem.collapse('show');
        } else {
          elem.addClass('collapse').collapse('hide');
        }
      } else {
        elem.collapse({
          parent: false,
          toggle: showNow
        })
          .addClass('collapse');
        elem.collapse(showNow ? 'show' : 'hide');
        if (showNow) {
          elem.addClass('in');
        }
      }
    },

    isCollapsed: function ($, elem) {
      return elem.data('bs.collapse') && elem.hasClass('collapsed');
    },

    shouldChange: function ($, elem, setName) {
      var change = null;
      var previousWasMobile = $(elem).data('SlnswTheme.CollapserTool.PreviousWasMobile');
      var previousAction = $(elem).data('SlnswTheme.CollapserTool.PreviousAction');

      if (setName === 'mobile' && !previousWasMobile) {
        // Change based on whether it's mobile or desktop.
        if (SlnswTheme.resize.breakpoints.isMobile()) {
          change = 'hide';
        } else {
          change = 'show';
        }
      } else if (setName === 'always') {
        // Don't bother changing, we want to keep it the same.
        change = previousAction || 'hide';
      }
      return change;
    },

    change: function ($, elem, _setName, change) {
      if (change === 'hide') {
        elem.collapse('hide').addClass('collapse');
        $('[data-slnswtools-nav-target="' + elem.attr('id') + '"]').attr({
          'aria-hidden': 'false'
        }).show();
      } else if (change === 'show') {
        elem.collapse('show');
        $('[data-slnswtools-nav-target="' + elem.attr('id') + '"]').attr({
          'aria-hidden': 'true'
        }).hide();
      }
    },
    addUX: function ($, elem, utils) {
      var id = utils.getID(elem);
      var button = $('[data-slnswtools-nav-target="' + id + '"]');
      var baseToggleText = elem.attr('data-slnswtools-nav-toggletext');
      var countItems = elem.attr('data-slnswtools-nav-countitems');
      var position = 'after';

      if (!button.length) {
        button = $('<button>', {
          type: 'button',
          'data-slnswtools-nav-target': id,
          class: 'show-more-action'
        });

        if (countItems) {
          button.append('<strong class="slnsw-nav-toggle-text">' + baseToggleText + '</strong> ')
            .append('<span class="slnsw-nav-toggle-count">(' + countItems + ')</span>');
        } else {
          button.text(baseToggleText);
        }

        button.click(function (e) {
          e.preventDefault();
          e.stopImmediatePropagation();
          elem.collapse('toggle');
        });

        elem.on('show.bs.collapse', function () {
          if (utils.mutateUX) {
            utils.mutateUX($(this), 'show');
          }
        });

        elem.on('hide.bs.collapse', function () {
          if (utils.mutateUX) {
            utils.mutateUX($(this), 'hide');
          }
        });
      }

      if (elem.attr('data-slnswtools-nav-position')) {
        position = elem.attr('data-slnswtools-nav-position');
      }

      switch (position) {
        case 'before':
          elem.before(button);
          break;

        case 'after':
        default:
          elem.after(button);
          break;
      }
    },
    mutateUX: function ($, elem, action) {
      var text = elem.attr('data-slnswtools-nav-toggletext');
      var count = elem.attr('data-slnswtools-nav-countitems');
      var button = $('[data-slnswtools-nav-target="' + elem.attr('id') + '"]');
      var tryText = elem.attr('data-slnswtools-nav-toggleofftext');

      if (action === 'show' && !!tryText) {
        text = tryText;
      }

      if (count) {
        button.find('.slnsw-nav-toggle-text').text(text);
      } else {
        button.text(text);
      }

      button.attr('aria-expanded', (action === 'show') ? 'true' : 'false');
      // Unfocus button.
      button.blur();
    }
  }
});
SlnswTheme.collapsers.navBehaviour.init('slnswTools.navBehaviour');

// Collapser: Fieldset
SlnswTheme.collapsers.formFieldset = new SlnswTheme.CollapserTool(jQuery, {
  selectors: {
    base: 'fieldset[data-slnswtools-fieldset-behaviour]',
    sets: {
      mobile: '[data-slnswtools-fieldset-behaviour="collapse-mobile"]',
      always: '[data-slnswtools-fieldset-behaviour="collapse-always"]'
    },
  },
  actions: {
    initElem: function ($, elem, _setName, showNow) {
      var inner = elem.children('.panel-collapse');
      var title = elem.find('> legend > .panel-title');

      if (showNow) {
        title.attr({ 'aria-expanded': 'true' });
        inner.removeClass('collapsed collapse fade').addClass('in').height('');
      } else {
        title.attr({ 'aria-expanded': 'true' });
        inner.removeClass('collapsed collapse fade').addClass('in').height('');
      }
    },

    isCollapsed: function ($, elem) {
      var inner = elem.children('.panel-collapse');
      if (inner.length) {
        return inner.hasClass('collapsed');
      }
      return elem.hasClass('collapsed');
    },

    shouldChange: function ($, elem, setName) {
      var change = null;
      if (setName === 'mobile') {
        change = SlnswTheme.resize.breakpoints.isMobile() ? 'hide' : 'show';
      }
      return change;
    },

    change: function ($, elem, _setName, change) {
      var inner = elem.children('.panel-collapse');
      var title = elem.find('> legend > .panel-title');

      if (change === 'hide') {
        title.attr({ 'aria-expanded': 'false' });
        inner.addClass('collapsed collapse fade').removeClass('in').height(0);
      } else if (change === 'show') {
        title.attr({ 'aria-expanded': 'true' });
        inner.removeClass('collapsed collapse fade').addClass('in').height('');
      }
    },

    addUX: function ($, elem, utils) {
      // data-slnswtools-fieldset-countitems
      var countItems = elem.attr('data-slnswtools-fieldset-countitems');

      if (countItems) {
        elem.find('> legend .panel-title')
          .append(' <span class="slnsw-nav-toggle-count visible-xs-inline">(' + countItems + ')</span>');
      }
    },

    mutateUX: function ($, elem, action) {
    }
  }
});
SlnswTheme.collapsers.formFieldset.init('slnswTools.formFieldset');

SlnswTheme.YouTube.init($);

// @file
// Events & Exhibitions Content types.

/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable func-names */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable wrap-iife */
(function ($) {
  /**
   * Whether we have a valid selector.
   */
  var buttonSelector = function (button) {
    var t = button.attr('data-target') || button.attr('data-taget');
    if (!t) {
      return null;
    }
    return t.indexOf('#') === 0 ? t : '.' + t;
  };

  /**
   * Hide any Show More buttons that can't expose any more.
   */
  var checkVisible = function (button) {
    var tSelector = buttonSelector(button);
    if (!tSelector) {
      return;
    }

    if (!$(tSelector).filter(':hidden').length) {
      button.addClass('hidden').attr('aria-hidden', 'true');
    }
  };

  /**
   * Attaches the show more .fn on events.
   */
  var showMoreAction = function (button) {
    var tSelector = buttonSelector(button);
    if (!tSelector) {
      return;
    }

    $(
      tSelector + ':visible + ' + tSelector + ':hidden, '
      + tSelector + ':visible + ' + tSelector + ':hidden + ' + tSelector + ':hidden'
    ).addClass('sl-loaded');

    checkVisible(button);
  };

  $('.exhibition__showmore').each(function () {
    checkVisible($(this));
  });

  $('[data-taget]:not(.details), [data-target]:not(.details)', document).on('click', function (e) {
    e.preventDefault();
    showMoreAction($(this));
  });
})(jQuery);

var NoEmbed = function(elem){
  var url = elem.find('a').attr('href');
  var embedNow = function(response){
    elem.append(response.html);
    elem.closest('[data-slnswtheme-masonry]').trigger('slnswtheme-noembed-load');
  };
  $.ajax({
    url: "//noembed.com/embed",
    data: {
      url: url
    },
    jsonp: "callback",
    dataType: "jsonp",
    success: embedNow
  });
};
$('[data-slnswtheme-noembed]').each(function(){
  var e = new NoEmbed($(this));
});